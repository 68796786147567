import(/* webpackMode: "eager" */ "/home/runner/work/kc-fonden/kc-fonden/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/kc-fonden/kc-fonden/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/kc-fonden/kc-fonden/src/sections/home/HomeHero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/kc-fonden/kc-fonden/src/shadcn/navigation/home-footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/kc-fonden/kc-fonden/src/shadcn/navigation/language-popover.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModeToggle"] */ "/home/runner/work/kc-fonden/kc-fonden/src/shadcn/theme-provider/toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTrigger","AccordionContent"] */ "/home/runner/work/kc-fonden/kc-fonden/src/shadcn/ui/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuTrigger","DropdownMenuContent","DropdownMenuLabel","DropdownMenuSeparator","DropdownMenuItem"] */ "/home/runner/work/kc-fonden/kc-fonden/src/shadcn/ui/dropdown-menu.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/kc-fonden/kc-fonden/src/shadcn/ui/sheet.tsx");
